










import Vue from 'vue';
import Component from 'vue-class-component';
import MainHeader from '@/components/layout/MainHeader.vue';
import $ from 'jquery';

@Component({ components: { MainHeader } })
export default class BaseLayout extends Vue {
  public selectLang: string = '';

  public created() {
    if (localStorage.getItem('OS_DEPOSIT_LANG')) {
      // if (
      //   location.href.includes('lang=en') &&
      //   String(localStorage.getItem('OS_DEPOSIT_LANG')) !== 'en'
      // ) {
      //   this.selectLang = 'en';
      //   localStorage.setItem('OS_DEPOSIT_LANG', 'en');
      //   return;
      // }
      this.selectLang = String(localStorage.getItem('OS_DEPOSIT_LANG'));
      localStorage.setItem('OS_DEPOSIT_LANG', String(localStorage.getItem('OS_DEPOSIT_LANG')));
      if (location.href.includes('lang=en')) {
        setTimeout(() => {
          $('span[title=EN]').click();
        }, 200);
      }
    } else {
      // if (location.href.includes('lang=en')) {
      //   this.selectLang = 'en';
      //   localStorage.setItem('OS_DEPOSIT_LANG', 'en');
      //   return;
      // }
      this.selectLang = 'ko'
      localStorage.setItem('OS_DEPOSIT_LANG', 'ko');
    }
    if (
      location.href.includes('lang=en') &&
      this.selectLang !== 'en'
    ) {
      setTimeout(() => {
        $('span[title=EN]').click();
      }, 200);
    }
    setTimeout(() => {
      if (
        location.href.includes('lang=en') &&
        this.selectLang !== 'en'
      ) {
        setTimeout(() => {
          $('span[title=EN]').click();
        }, 200);
      }
    }, 3000);
    if (
      location.href.includes('lang=jp') &&
      this.selectLang !== 'ja'
    ) {
      setTimeout(() => {
        $('span[title=JP]').click();
      }, 200);
    }
  }
  public langUpdate(data: any) {
    this.selectLang = data;
    localStorage.setItem('OS_DEPOSIT_LANG', data);
  }
}
